export const user = () => {
	const user = useSanctumUser()

	return toValue(user)?.data
}

export const branch = () => {
	const branch = useCookie('branch')

	return branch.value
}

export const setBranch = (id, title) => {
	const branch = useCookie('branch')

	branch.value = {
		id,
		title,
	}
}

export const checkRole = (roles = null) => {
	if (user().role === 'admin') return true
	else if (!roles) return false

	return roles.find((role) => role === user().role) !== undefined
}
